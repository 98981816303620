<script lang="ts" setup></script>

<template>
	<div class="qr-code flex-vertical">
		<svg class="qr-code" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="48" height="48" rx="8" fill="white" />
			<path d="M13.5547 13.5547H15.7422V15.7422H13.5547V13.5547Z" fill="black" />
			<path fill-rule="evenodd" clip-rule="evenodd" d="M10 10H19.2969V19.2969H10V10ZM12.1875 17.1094H17.1094V12.1875H12.1875V17.1094Z" fill="black" />
			<path d="M15.7422 32.2578H13.5547V34.4453H15.7422V32.2578Z" fill="black" />
			<path fill-rule="evenodd" clip-rule="evenodd" d="M10 28.7031H19.2969V38H10V28.7031ZM17.1094 30.8906V35.8125H12.1875V30.8906H17.1094Z" fill="black" />
			<path d="M32.2578 13.5547H34.4453V15.7422H32.2578V13.5547Z" fill="black" />
			<path fill-rule="evenodd" clip-rule="evenodd" d="M38 10H28.7031V19.2969H38V10ZM35.8125 17.1094V12.1875H30.8906V17.1094H35.8125Z" fill="black" />
			<path d="M20.9375 12.1875H23.125V16.3984H20.9375V12.1875Z" fill="black" />
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M25.3125 16.3984H23.125V18.5859H20.9375V20.7734H16.5625V22.9609H14.375V20.7734H12.1875V22.9609H10V27.3359H12.1875V22.9609H14.375V25.1484H16.5625V27.3359H18.75V25.1484H16.5625V22.9609H20.9375V27.3359H23.125V29.5234H20.9375V33.625H23.125V38H25.3125V35.8125H27.5V38H29.6875V35.8125H27.5V33.625H23.125V29.5234H25.3125V31.7109H29.6875V33.8984H31.875V38H34.0625V36.0859H35.8125V33.8984H38V29.5234H35.8125V33.8984H31.875V31.7109H34.0625V29.5234H31.875V31.7109H29.6875V29.5234H25.3125V27.3359H23.125V22.9609H20.9375V20.7734H25.3125V25.1484H27.5V27.3359H29.6875V25.1484H31.875V27.3359H38V22.9609H35.8125V20.7734H31.875V22.9609H29.6875V25.1484H27.5V20.7734H25.3125V16.3984ZM31.875 22.9609V25.1484H35.8125V22.9609H31.875Z"
				fill="black"
			/>
			<path d="M25.3125 16.3984V14.2109H27.5V16.3984H25.3125Z" fill="black" />
			<path d="M23.125 12.1875V10H25.3125V12.1875H23.125Z" fill="black" />
		</svg>

		<div class="hover">
			<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M46.6667 0H42.4243V4.24242H46.6667V0Z" fill="black" />
				<path d="M50.9091 0H46.6667V4.24242H50.9091V0Z" fill="black" />
				<path d="M59.3939 0H55.1515V4.24242H59.3939V0Z" fill="black" />
				<path d="M76.3636 0H72.1212V4.24242H76.3636V0Z" fill="black" />
				<path d="M80.6061 0H76.3636V4.24242H80.6061V0Z" fill="black" />
				<path d="M84.8485 0H80.6061V4.24242H84.8485V0Z" fill="black" />
				<path d="M89.0909 0H84.8485V4.24242H89.0909V0Z" fill="black" />
				<path d="M93.3334 0H89.0909V4.24242H93.3334V0Z" fill="black" />
				<path d="M101.818 0H97.5757V4.24242H101.818V0Z" fill="black" />
				<path d="M46.6667 4.24243H42.4243V8.48486H46.6667V4.24243Z" fill="black" />
				<path d="M59.3939 4.24243H55.1515V8.48486H59.3939V4.24243Z" fill="black" />
				<path d="M63.6363 4.24243H59.3939V8.48486H63.6363V4.24243Z" fill="black" />
				<path d="M67.8788 4.24243H63.6364V8.48486H67.8788V4.24243Z" fill="black" />
				<path d="M76.3636 4.24243H72.1212V8.48486H76.3636V4.24243Z" fill="black" />
				<path d="M89.0909 4.24243H84.8485V8.48486H89.0909V4.24243Z" fill="black" />
				<path d="M93.3334 4.24243H89.0909V8.48486H93.3334V4.24243Z" fill="black" />
				<path d="M106.061 4.24243H101.818V8.48486H106.061V4.24243Z" fill="black" />
				<path d="M38.1818 8.48486H33.9394V12.7273H38.1818V8.48486Z" fill="black" />
				<path d="M42.4242 8.48486H38.1818V12.7273H42.4242V8.48486Z" fill="black" />
				<path d="M59.3939 8.48486H55.1515V12.7273H59.3939V8.48486Z" fill="black" />
				<path d="M67.8788 8.48486H63.6364V12.7273H67.8788V8.48486Z" fill="black" />
				<path d="M76.3636 8.48486H72.1212V12.7273H76.3636V8.48486Z" fill="black" />
				<path d="M80.6061 8.48486H76.3636V12.7273H80.6061V8.48486Z" fill="black" />
				<path d="M84.8485 8.48486H80.6061V12.7273H84.8485V8.48486Z" fill="black" />
				<path d="M89.0909 8.48486H84.8485V12.7273H89.0909V8.48486Z" fill="black" />
				<path d="M93.3334 8.48486H89.0909V12.7273H93.3334V8.48486Z" fill="black" />
				<path d="M97.5757 8.48486H93.3333V12.7273H97.5757V8.48486Z" fill="black" />
				<path d="M101.818 8.48486H97.5757V12.7273H101.818V8.48486Z" fill="black" />
				<path d="M38.1818 12.7273H33.9394V16.9697H38.1818V12.7273Z" fill="black" />
				<path d="M42.4242 12.7273H38.1818V16.9697H42.4242V12.7273Z" fill="black" />
				<path d="M46.6667 12.7273H42.4243V16.9697H46.6667V12.7273Z" fill="black" />
				<path d="M50.9091 12.7273H46.6667V16.9697H50.9091V12.7273Z" fill="black" />
				<path d="M55.1515 12.7273H50.9091V16.9697H55.1515V12.7273Z" fill="black" />
				<path d="M63.6363 12.7273H59.3939V16.9697H63.6363V12.7273Z" fill="black" />
				<path d="M67.8788 12.7273H63.6364V16.9697H67.8788V12.7273Z" fill="black" />
				<path d="M72.1212 12.7273H67.8788V16.9697H72.1212V12.7273Z" fill="black" />
				<path d="M84.8485 12.7273H80.6061V16.9697H84.8485V12.7273Z" fill="black" />
				<path d="M89.0909 12.7273H84.8485V16.9697H89.0909V12.7273Z" fill="black" />
				<path d="M101.818 12.7273H97.5757V16.9697H101.818V12.7273Z" fill="black" />
				<path d="M38.1818 16.9697H33.9394V21.2122H38.1818V16.9697Z" fill="black" />
				<path d="M46.6667 16.9697H42.4243V21.2122H46.6667V16.9697Z" fill="black" />
				<path d="M50.9091 16.9697H46.6667V21.2122H50.9091V16.9697Z" fill="black" />
				<path d="M59.3939 16.9697H55.1515V21.2122H59.3939V16.9697Z" fill="black" />
				<path d="M67.8788 16.9697H63.6364V21.2122H67.8788V16.9697Z" fill="black" />
				<path d="M76.3636 16.9697H72.1212V21.2122H76.3636V16.9697Z" fill="black" />
				<path d="M80.6061 16.9697H76.3636V21.2122H80.6061V16.9697Z" fill="black" />
				<path d="M93.3334 16.9697H89.0909V21.2122H93.3334V16.9697Z" fill="black" />
				<path d="M97.5757 16.9697H93.3333V21.2122H97.5757V16.9697Z" fill="black" />
				<path d="M101.818 16.9697H97.5757V21.2122H101.818V16.9697Z" fill="black" />
				<path d="M106.061 16.9697H101.818V21.2122H106.061V16.9697Z" fill="black" />
				<path d="M38.1818 21.2121H33.9394V25.4545H38.1818V21.2121Z" fill="black" />
				<path d="M42.4242 21.2121H38.1818V25.4545H42.4242V21.2121Z" fill="black" />
				<path d="M50.9091 21.2121H46.6667V25.4545H50.9091V21.2121Z" fill="black" />
				<path d="M59.3939 21.2121H55.1515V25.4545H59.3939V21.2121Z" fill="black" />
				<path d="M63.6363 21.2121H59.3939V25.4545H63.6363V21.2121Z" fill="black" />
				<path d="M67.8788 21.2121H63.6364V25.4545H67.8788V21.2121Z" fill="black" />
				<path d="M72.1212 21.2121H67.8788V25.4545H72.1212V21.2121Z" fill="black" />
				<path d="M89.0909 21.2121H84.8485V25.4545H89.0909V21.2121Z" fill="black" />
				<path d="M93.3334 21.2121H89.0909V25.4545H93.3334V21.2121Z" fill="black" />
				<path d="M106.061 21.2121H101.818V25.4545H106.061V21.2121Z" fill="black" />
				<path d="M38.1818 25.4545H33.9394V29.697H38.1818V25.4545Z" fill="black" />
				<path d="M46.6667 25.4545H42.4243V29.697H46.6667V25.4545Z" fill="black" />
				<path d="M55.1515 25.4545H50.9091V29.697H55.1515V25.4545Z" fill="black" />
				<path d="M63.6363 25.4545H59.3939V29.697H63.6363V25.4545Z" fill="black" />
				<path d="M72.1212 25.4545H67.8788V29.697H72.1212V25.4545Z" fill="black" />
				<path d="M80.6061 25.4545H76.3636V29.697H80.6061V25.4545Z" fill="black" />
				<path d="M89.0909 25.4545H84.8485V29.697H89.0909V25.4545Z" fill="black" />
				<path d="M97.5757 25.4545H93.3333V29.697H97.5757V25.4545Z" fill="black" />
				<path d="M106.061 25.4545H101.818V29.697H106.061V25.4545Z" fill="black" />
				<path d="M38.1818 29.697H33.9394V33.9394H38.1818V29.697Z" fill="black" />
				<path d="M46.6667 29.697H42.4243V33.9394H46.6667V29.697Z" fill="black" />
				<path d="M50.9091 29.697H46.6667V33.9394H50.9091V29.697Z" fill="black" />
				<path d="M63.6363 29.697H59.3939V33.9394H63.6363V29.697Z" fill="black" />
				<path d="M67.8788 29.697H63.6364V33.9394H67.8788V29.697Z" fill="black" />
				<path d="M93.3334 29.697H89.0909V33.9394H93.3334V29.697Z" fill="black" />
				<path d="M97.5757 29.697H93.3333V33.9394H97.5757V29.697Z" fill="black" />
				<path d="M4.24242 33.9394H0V38.1818H4.24242V33.9394Z" fill="black" />
				<path d="M12.7273 33.9394H8.48486V38.1818H12.7273V33.9394Z" fill="black" />
				<path d="M16.9697 33.9394H12.7273V38.1818H16.9697V33.9394Z" fill="black" />
				<path d="M21.2122 33.9394H16.9697V38.1818H21.2122V33.9394Z" fill="black" />
				<path d="M25.4545 33.9394H21.2121V38.1818H25.4545V33.9394Z" fill="black" />
				<path d="M29.697 33.9394H25.4545V38.1818H29.697V33.9394Z" fill="black" />
				<path d="M59.3939 33.9394H55.1515V38.1818H59.3939V33.9394Z" fill="black" />
				<path d="M63.6363 33.9394H59.3939V38.1818H63.6363V33.9394Z" fill="black" />
				<path d="M67.8788 33.9394H63.6364V38.1818H67.8788V33.9394Z" fill="black" />
				<path d="M72.1212 33.9394H67.8788V38.1818H72.1212V33.9394Z" fill="black" />
				<path d="M76.3636 33.9394H72.1212V38.1818H76.3636V33.9394Z" fill="black" />
				<path d="M80.6061 33.9394H76.3636V38.1818H80.6061V33.9394Z" fill="black" />
				<path d="M97.5757 33.9394H93.3333V38.1818H97.5757V33.9394Z" fill="black" />
				<path d="M106.061 33.9394H101.818V38.1818H106.061V33.9394Z" fill="black" />
				<path d="M114.545 33.9394H110.303V38.1818H114.545V33.9394Z" fill="black" />
				<path d="M118.788 33.9394H114.545V38.1818H118.788V33.9394Z" fill="black" />
				<path d="M123.03 33.9394H118.788V38.1818H123.03V33.9394Z" fill="black" />
				<path d="M127.273 33.9394H123.03V38.1818H127.273V33.9394Z" fill="black" />
				<path d="M131.515 33.9394H127.273V38.1818H131.515V33.9394Z" fill="black" />
				<path d="M4.24242 38.1818H0V42.4242H4.24242V38.1818Z" fill="black" />
				<path d="M8.48486 38.1818H4.24243V42.4242H8.48486V38.1818Z" fill="black" />
				<path d="M21.2122 38.1818H16.9697V42.4242H21.2122V38.1818Z" fill="black" />
				<path d="M25.4545 38.1818H21.2121V42.4242H25.4545V38.1818Z" fill="black" />
				<path d="M33.9394 38.1818H29.697V42.4242H33.9394V38.1818Z" fill="black" />
				<path d="M42.4242 38.1818H38.1818V42.4242H42.4242V38.1818Z" fill="black" />
				<path d="M46.6667 38.1818H42.4243V42.4242H46.6667V38.1818Z" fill="black" />
				<path d="M50.9091 38.1818H46.6667V42.4242H50.9091V38.1818Z" fill="black" />
				<path d="M55.1515 38.1818H50.9091V42.4242H55.1515V38.1818Z" fill="black" />
				<path d="M72.1212 38.1818H67.8788V42.4242H72.1212V38.1818Z" fill="black" />
				<path d="M80.6061 38.1818H76.3636V42.4242H80.6061V38.1818Z" fill="black" />
				<path d="M84.8485 38.1818H80.6061V42.4242H84.8485V38.1818Z" fill="black" />
				<path d="M89.0909 38.1818H84.8485V42.4242H89.0909V38.1818Z" fill="black" />
				<path d="M93.3334 38.1818H89.0909V42.4242H93.3334V38.1818Z" fill="black" />
				<path d="M97.5757 38.1818H93.3333V42.4242H97.5757V38.1818Z" fill="black" />
				<path d="M101.818 38.1818H97.5757V42.4242H101.818V38.1818Z" fill="black" />
				<path d="M106.061 38.1818H101.818V42.4242H106.061V38.1818Z" fill="black" />
				<path d="M114.545 38.1818H110.303V42.4242H114.545V38.1818Z" fill="black" />
				<path d="M118.788 38.1818H114.545V42.4242H118.788V38.1818Z" fill="black" />
				<path d="M127.273 38.1818H123.03V42.4242H127.273V38.1818Z" fill="black" />
				<path d="M131.515 38.1818H127.273V42.4242H131.515V38.1818Z" fill="black" />
				<path d="M140 38.1818H135.758V42.4242H140V38.1818Z" fill="black" />
				<path d="M8.48486 42.4243H4.24243V46.6667H8.48486V42.4243Z" fill="black" />
				<path d="M21.2122 42.4243H16.9697V46.6667H21.2122V42.4243Z" fill="black" />
				<path d="M25.4545 42.4243H21.2121V46.6667H25.4545V42.4243Z" fill="black" />
				<path d="M29.697 42.4243H25.4545V46.6667H29.697V42.4243Z" fill="black" />
				<path d="M33.9394 42.4243H29.697V46.6667H33.9394V42.4243Z" fill="black" />
				<path d="M42.4242 42.4243H38.1818V46.6667H42.4242V42.4243Z" fill="black" />
				<path d="M46.6667 42.4243H42.4243V46.6667H46.6667V42.4243Z" fill="black" />
				<path d="M50.9091 42.4243H46.6667V46.6667H50.9091V42.4243Z" fill="black" />
				<path d="M67.8788 42.4243H63.6364V46.6667H67.8788V42.4243Z" fill="black" />
				<path d="M80.6061 42.4243H76.3636V46.6667H80.6061V42.4243Z" fill="black" />
				<path d="M89.0909 42.4243H84.8485V46.6667H89.0909V42.4243Z" fill="black" />
				<path d="M93.3334 42.4243H89.0909V46.6667H93.3334V42.4243Z" fill="black" />
				<path d="M114.545 42.4243H110.303V46.6667H114.545V42.4243Z" fill="black" />
				<path d="M123.03 42.4243H118.788V46.6667H123.03V42.4243Z" fill="black" />
				<path d="M131.515 42.4243H127.273V46.6667H131.515V42.4243Z" fill="black" />
				<path d="M135.758 42.4243H131.515V46.6667H135.758V42.4243Z" fill="black" />
				<path d="M4.24242 46.6667H0V50.9091H4.24242V46.6667Z" fill="black" />
				<path d="M8.48486 46.6667H4.24243V50.9091H8.48486V46.6667Z" fill="black" />
				<path d="M12.7273 46.6667H8.48486V50.9091H12.7273V46.6667Z" fill="black" />
				<path d="M33.9394 46.6667H29.697V50.9091H33.9394V46.6667Z" fill="black" />
				<path d="M42.4242 46.6667H38.1818V50.9091H42.4242V46.6667Z" fill="black" />
				<path d="M50.9091 46.6667H46.6667V50.9091H50.9091V46.6667Z" fill="black" />
				<path d="M63.6363 46.6667H59.3939V50.9091H63.6363V46.6667Z" fill="black" />
				<path d="M67.8788 46.6667H63.6364V50.9091H67.8788V46.6667Z" fill="black" />
				<path d="M84.8485 46.6667H80.6061V50.9091H84.8485V46.6667Z" fill="black" />
				<path d="M93.3334 46.6667H89.0909V50.9091H93.3334V46.6667Z" fill="black" />
				<path d="M97.5757 46.6667H93.3333V50.9091H97.5757V46.6667Z" fill="black" />
				<path d="M101.818 46.6667H97.5757V50.9091H101.818V46.6667Z" fill="black" />
				<path d="M106.061 46.6667H101.818V50.9091H106.061V46.6667Z" fill="black" />
				<path d="M110.303 46.6667H106.061V50.9091H110.303V46.6667Z" fill="black" />
				<path d="M123.03 46.6667H118.788V50.9091H123.03V46.6667Z" fill="black" />
				<path d="M127.273 46.6667H123.03V50.9091H127.273V46.6667Z" fill="black" />
				<path d="M131.515 46.6667H127.273V50.9091H131.515V46.6667Z" fill="black" />
				<path d="M135.758 46.6667H131.515V50.9091H135.758V46.6667Z" fill="black" />
				<path d="M4.24242 50.9091H0V55.1515H4.24242V50.9091Z" fill="black" />
				<path d="M8.48486 50.9091H4.24243V55.1515H8.48486V50.9091Z" fill="black" />
				<path d="M16.9697 50.9091H12.7273V55.1515H16.9697V50.9091Z" fill="black" />
				<path d="M29.697 50.9091H25.4545V55.1515H29.697V50.9091Z" fill="black" />
				<path d="M38.1818 50.9091H33.9394V55.1515H38.1818V50.9091Z" fill="black" />
				<path d="M42.4242 50.9091H38.1818V55.1515H42.4242V50.9091Z" fill="black" />
				<path d="M50.9091 50.9091H46.6667V55.1515H50.9091V50.9091Z" fill="black" />
				<path d="M55.1515 50.9091H50.9091V55.1515H55.1515V50.9091Z" fill="black" />
				<path d="M67.8788 50.9091H63.6364V55.1515H67.8788V50.9091Z" fill="black" />
				<path d="M80.6061 50.9091H76.3636V55.1515H80.6061V50.9091Z" fill="black" />
				<path d="M110.303 50.9091H106.061V55.1515H110.303V50.9091Z" fill="black" />
				<path d="M118.788 50.9091H114.545V55.1515H118.788V50.9091Z" fill="black" />
				<path d="M123.03 50.9091H118.788V55.1515H123.03V50.9091Z" fill="black" />
				<path d="M127.273 50.9091H123.03V55.1515H127.273V50.9091Z" fill="black" />
				<path d="M135.758 50.9091H131.515V55.1515H135.758V50.9091Z" fill="black" />
				<path d="M140 50.9091H135.758V55.1515H140V50.9091Z" fill="black" />
				<path d="M8.48486 55.1515H4.24243V59.3939H8.48486V55.1515Z" fill="black" />
				<path d="M21.2122 55.1515H16.9697V59.3939H21.2122V55.1515Z" fill="black" />
				<path d="M33.9394 55.1515H29.697V59.3939H33.9394V55.1515Z" fill="black" />
				<path d="M46.6667 55.1515H42.4243V59.3939H46.6667V55.1515Z" fill="black" />
				<path d="M50.9091 55.1515H46.6667V59.3939H50.9091V55.1515Z" fill="black" />
				<path d="M63.6363 55.1515H59.3939V59.3939H63.6363V55.1515Z" fill="black" />
				<path d="M67.8788 55.1515H63.6364V59.3939H67.8788V55.1515Z" fill="black" />
				<path d="M72.1212 55.1515H67.8788V59.3939H72.1212V55.1515Z" fill="black" />
				<path d="M76.3636 55.1515H72.1212V59.3939H76.3636V55.1515Z" fill="black" />
				<path d="M89.0909 55.1515H84.8485V59.3939H89.0909V55.1515Z" fill="black" />
				<path d="M93.3334 55.1515H89.0909V59.3939H93.3334V55.1515Z" fill="black" />
				<path d="M97.5757 55.1515H93.3333V59.3939H97.5757V55.1515Z" fill="black" />
				<path d="M101.818 55.1515H97.5757V59.3939H101.818V55.1515Z" fill="black" />
				<path d="M106.061 55.1515H101.818V59.3939H106.061V55.1515Z" fill="black" />
				<path d="M114.545 55.1515H110.303V59.3939H114.545V55.1515Z" fill="black" />
				<path d="M135.758 55.1515H131.515V59.3939H135.758V55.1515Z" fill="black" />
				<path d="M140 55.1515H135.758V59.3939H140V55.1515Z" fill="black" />
				<path d="M4.24242 59.3939H0V63.6363H4.24242V59.3939Z" fill="black" />
				<path d="M12.7273 59.3939H8.48486V63.6363H12.7273V59.3939Z" fill="black" />
				<path d="M16.9697 59.3939H12.7273V63.6363H16.9697V59.3939Z" fill="black" />
				<path d="M25.4545 59.3939H21.2121V63.6363H25.4545V59.3939Z" fill="black" />
				<path d="M29.697 59.3939H25.4545V63.6363H29.697V59.3939Z" fill="black" />
				<path d="M33.9394 59.3939H29.697V63.6363H33.9394V59.3939Z" fill="black" />
				<path d="M50.9091 59.3939H46.6667V63.6363H50.9091V59.3939Z" fill="black" />
				<path d="M59.3939 59.3939H55.1515V63.6363H59.3939V59.3939Z" fill="black" />
				<path d="M76.3636 59.3939H72.1212V63.6363H76.3636V59.3939Z" fill="black" />
				<path d="M84.8485 59.3939H80.6061V63.6363H84.8485V59.3939Z" fill="black" />
				<path d="M93.3334 59.3939H89.0909V63.6363H93.3334V59.3939Z" fill="black" />
				<path d="M110.303 59.3939H106.061V63.6363H110.303V59.3939Z" fill="black" />
				<path d="M114.545 59.3939H110.303V63.6363H114.545V59.3939Z" fill="black" />
				<path d="M123.03 59.3939H118.788V63.6363H123.03V59.3939Z" fill="black" />
				<path d="M127.273 59.3939H123.03V63.6363H127.273V59.3939Z" fill="black" />
				<path d="M131.515 59.3939H127.273V63.6363H131.515V59.3939Z" fill="black" />
				<path d="M135.758 59.3939H131.515V63.6363H135.758V59.3939Z" fill="black" />
				<path d="M4.24242 63.6364H0V67.8788H4.24242V63.6364Z" fill="black" />
				<path d="M12.7273 63.6364H8.48486V67.8788H12.7273V63.6364Z" fill="black" />
				<path d="M21.2122 63.6364H16.9697V67.8788H21.2122V63.6364Z" fill="black" />
				<path d="M25.4545 63.6364H21.2121V67.8788H25.4545V63.6364Z" fill="black" />
				<path d="M33.9394 63.6364H29.697V67.8788H33.9394V63.6364Z" fill="black" />
				<path d="M46.6667 63.6364H42.4243V67.8788H46.6667V63.6364Z" fill="black" />
				<path d="M55.1515 63.6364H50.9091V67.8788H55.1515V63.6364Z" fill="black" />
				<path d="M72.1212 63.6364H67.8788V67.8788H72.1212V63.6364Z" fill="black" />
				<path d="M84.8485 63.6364H80.6061V67.8788H84.8485V63.6364Z" fill="black" />
				<path d="M89.0909 63.6364H84.8485V67.8788H89.0909V63.6364Z" fill="black" />
				<path d="M93.3334 63.6364H89.0909V67.8788H93.3334V63.6364Z" fill="black" />
				<path d="M101.818 63.6364H97.5757V67.8788H101.818V63.6364Z" fill="black" />
				<path d="M110.303 63.6364H106.061V67.8788H110.303V63.6364Z" fill="black" />
				<path d="M114.545 63.6364H110.303V67.8788H114.545V63.6364Z" fill="black" />
				<path d="M123.03 63.6364H118.788V67.8788H123.03V63.6364Z" fill="black" />
				<path d="M127.273 63.6364H123.03V67.8788H127.273V63.6364Z" fill="black" />
				<path d="M131.515 63.6364H127.273V67.8788H131.515V63.6364Z" fill="black" />
				<path d="M8.48486 67.8788H4.24243V72.1212H8.48486V67.8788Z" fill="black" />
				<path d="M16.9697 67.8788H12.7273V72.1212H16.9697V67.8788Z" fill="black" />
				<path d="M29.697 67.8788H25.4545V72.1212H29.697V67.8788Z" fill="black" />
				<path d="M33.9394 67.8788H29.697V72.1212H33.9394V67.8788Z" fill="black" />
				<path d="M38.1818 67.8788H33.9394V72.1212H38.1818V67.8788Z" fill="black" />
				<path d="M46.6667 67.8788H42.4243V72.1212H46.6667V67.8788Z" fill="black" />
				<path d="M50.9091 67.8788H46.6667V72.1212H50.9091V67.8788Z" fill="black" />
				<path d="M55.1515 67.8788H50.9091V72.1212H55.1515V67.8788Z" fill="black" />
				<path d="M72.1212 67.8788H67.8788V72.1212H72.1212V67.8788Z" fill="black" />
				<path d="M76.3636 67.8788H72.1212V72.1212H76.3636V67.8788Z" fill="black" />
				<path d="M84.8485 67.8788H80.6061V72.1212H84.8485V67.8788Z" fill="black" />
				<path d="M97.5757 67.8788H93.3333V72.1212H97.5757V67.8788Z" fill="black" />
				<path d="M110.303 67.8788H106.061V72.1212H110.303V67.8788Z" fill="black" />
				<path d="M118.788 67.8788H114.545V72.1212H118.788V67.8788Z" fill="black" />
				<path d="M123.03 67.8788H118.788V72.1212H123.03V67.8788Z" fill="black" />
				<path d="M140 67.8788H135.758V72.1212H140V67.8788Z" fill="black" />
				<path d="M21.2122 72.1212H16.9697V76.3636H21.2122V72.1212Z" fill="black" />
				<path d="M33.9394 72.1212H29.697V76.3636H33.9394V72.1212Z" fill="black" />
				<path d="M63.6363 72.1212H59.3939V76.3636H63.6363V72.1212Z" fill="black" />
				<path d="M72.1212 72.1212H67.8788V76.3636H72.1212V72.1212Z" fill="black" />
				<path d="M76.3636 72.1212H72.1212V76.3636H76.3636V72.1212Z" fill="black" />
				<path d="M84.8485 72.1212H80.6061V76.3636H84.8485V72.1212Z" fill="black" />
				<path d="M89.0909 72.1212H84.8485V76.3636H89.0909V72.1212Z" fill="black" />
				<path d="M97.5757 72.1212H93.3333V76.3636H97.5757V72.1212Z" fill="black" />
				<path d="M101.818 72.1212H97.5757V76.3636H101.818V72.1212Z" fill="black" />
				<path d="M114.545 72.1212H110.303V76.3636H114.545V72.1212Z" fill="black" />
				<path d="M118.788 72.1212H114.545V76.3636H118.788V72.1212Z" fill="black" />
				<path d="M127.273 72.1212H123.03V76.3636H127.273V72.1212Z" fill="black" />
				<path d="M131.515 72.1212H127.273V76.3636H131.515V72.1212Z" fill="black" />
				<path d="M140 72.1212H135.758V76.3636H140V72.1212Z" fill="black" />
				<path d="M8.48486 76.3636H4.24243V80.6061H8.48486V76.3636Z" fill="black" />
				<path d="M21.2122 76.3636H16.9697V80.6061H21.2122V76.3636Z" fill="black" />
				<path d="M25.4545 76.3636H21.2121V80.6061H25.4545V76.3636Z" fill="black" />
				<path d="M29.697 76.3636H25.4545V80.6061H29.697V76.3636Z" fill="black" />
				<path d="M33.9394 76.3636H29.697V80.6061H33.9394V76.3636Z" fill="black" />
				<path d="M38.1818 76.3636H33.9394V80.6061H38.1818V76.3636Z" fill="black" />
				<path d="M46.6667 76.3636H42.4243V80.6061H46.6667V76.3636Z" fill="black" />
				<path d="M55.1515 76.3636H50.9091V80.6061H55.1515V76.3636Z" fill="black" />
				<path d="M67.8788 76.3636H63.6364V80.6061H67.8788V76.3636Z" fill="black" />
				<path d="M72.1212 76.3636H67.8788V80.6061H72.1212V76.3636Z" fill="black" />
				<path d="M76.3636 76.3636H72.1212V80.6061H76.3636V76.3636Z" fill="black" />
				<path d="M93.3334 76.3636H89.0909V80.6061H93.3334V76.3636Z" fill="black" />
				<path d="M106.061 76.3636H101.818V80.6061H106.061V76.3636Z" fill="black" />
				<path d="M110.303 76.3636H106.061V80.6061H110.303V76.3636Z" fill="black" />
				<path d="M118.788 76.3636H114.545V80.6061H118.788V76.3636Z" fill="black" />
				<path d="M123.03 76.3636H118.788V80.6061H123.03V76.3636Z" fill="black" />
				<path d="M131.515 76.3636H127.273V80.6061H131.515V76.3636Z" fill="black" />
				<path d="M135.758 76.3636H131.515V80.6061H135.758V76.3636Z" fill="black" />
				<path d="M8.48486 80.6061H4.24243V84.8485H8.48486V80.6061Z" fill="black" />
				<path d="M38.1818 80.6061H33.9394V84.8485H38.1818V80.6061Z" fill="black" />
				<path d="M55.1515 80.6061H50.9091V84.8485H55.1515V80.6061Z" fill="black" />
				<path d="M59.3939 80.6061H55.1515V84.8485H59.3939V80.6061Z" fill="black" />
				<path d="M67.8788 80.6061H63.6364V84.8485H67.8788V80.6061Z" fill="black" />
				<path d="M76.3636 80.6061H72.1212V84.8485H76.3636V80.6061Z" fill="black" />
				<path d="M80.6061 80.6061H76.3636V84.8485H80.6061V80.6061Z" fill="black" />
				<path d="M84.8485 80.6061H80.6061V84.8485H84.8485V80.6061Z" fill="black" />
				<path d="M89.0909 80.6061H84.8485V84.8485H89.0909V80.6061Z" fill="black" />
				<path d="M93.3334 80.6061H89.0909V84.8485H93.3334V80.6061Z" fill="black" />
				<path d="M97.5757 80.6061H93.3333V84.8485H97.5757V80.6061Z" fill="black" />
				<path d="M106.061 80.6061H101.818V84.8485H106.061V80.6061Z" fill="black" />
				<path d="M118.788 80.6061H114.545V84.8485H118.788V80.6061Z" fill="black" />
				<path d="M123.03 80.6061H118.788V84.8485H123.03V80.6061Z" fill="black" />
				<path d="M127.273 80.6061H123.03V84.8485H127.273V80.6061Z" fill="black" />
				<path d="M131.515 80.6061H127.273V84.8485H131.515V80.6061Z" fill="black" />
				<path d="M135.758 80.6061H131.515V84.8485H135.758V80.6061Z" fill="black" />
				<path d="M4.24242 84.8485H0V89.0909H4.24242V84.8485Z" fill="black" />
				<path d="M8.48486 84.8485H4.24243V89.0909H8.48486V84.8485Z" fill="black" />
				<path d="M12.7273 84.8485H8.48486V89.0909H12.7273V84.8485Z" fill="black" />
				<path d="M16.9697 84.8485H12.7273V89.0909H16.9697V84.8485Z" fill="black" />
				<path d="M29.697 84.8485H25.4545V89.0909H29.697V84.8485Z" fill="black" />
				<path d="M50.9091 84.8485H46.6667V89.0909H50.9091V84.8485Z" fill="black" />
				<path d="M59.3939 84.8485H55.1515V89.0909H59.3939V84.8485Z" fill="black" />
				<path d="M63.6363 84.8485H59.3939V89.0909H63.6363V84.8485Z" fill="black" />
				<path d="M67.8788 84.8485H63.6364V89.0909H67.8788V84.8485Z" fill="black" />
				<path d="M72.1212 84.8485H67.8788V89.0909H72.1212V84.8485Z" fill="black" />
				<path d="M84.8485 84.8485H80.6061V89.0909H84.8485V84.8485Z" fill="black" />
				<path d="M118.788 84.8485H114.545V89.0909H118.788V84.8485Z" fill="black" />
				<path d="M123.03 84.8485H118.788V89.0909H123.03V84.8485Z" fill="black" />
				<path d="M127.273 84.8485H123.03V89.0909H127.273V84.8485Z" fill="black" />
				<path d="M140 84.8485H135.758V89.0909H140V84.8485Z" fill="black" />
				<path d="M4.24242 89.0909H0V93.3334H4.24242V89.0909Z" fill="black" />
				<path d="M12.7273 89.0909H8.48486V93.3334H12.7273V89.0909Z" fill="black" />
				<path d="M33.9394 89.0909H29.697V93.3334H33.9394V89.0909Z" fill="black" />
				<path d="M38.1818 89.0909H33.9394V93.3334H38.1818V89.0909Z" fill="black" />
				<path d="M46.6667 89.0909H42.4243V93.3334H46.6667V89.0909Z" fill="black" />
				<path d="M55.1515 89.0909H50.9091V93.3334H55.1515V89.0909Z" fill="black" />
				<path d="M67.8788 89.0909H63.6364V93.3334H67.8788V89.0909Z" fill="black" />
				<path d="M80.6061 89.0909H76.3636V93.3334H80.6061V89.0909Z" fill="black" />
				<path d="M93.3334 89.0909H89.0909V93.3334H93.3334V89.0909Z" fill="black" />
				<path d="M97.5757 89.0909H93.3333V93.3334H97.5757V89.0909Z" fill="black" />
				<path d="M101.818 89.0909H97.5757V93.3334H101.818V89.0909Z" fill="black" />
				<path d="M106.061 89.0909H101.818V93.3334H106.061V89.0909Z" fill="black" />
				<path d="M110.303 89.0909H106.061V93.3334H110.303V89.0909Z" fill="black" />
				<path d="M114.545 89.0909H110.303V93.3334H114.545V89.0909Z" fill="black" />
				<path d="M118.788 89.0909H114.545V93.3334H118.788V89.0909Z" fill="black" />
				<path d="M131.515 89.0909H127.273V93.3334H131.515V89.0909Z" fill="black" />
				<path d="M140 89.0909H135.758V93.3334H140V89.0909Z" fill="black" />
				<path d="M4.24242 93.3333H0V97.5757H4.24242V93.3333Z" fill="black" />
				<path d="M16.9697 93.3333H12.7273V97.5757H16.9697V93.3333Z" fill="black" />
				<path d="M21.2122 93.3333H16.9697V97.5757H21.2122V93.3333Z" fill="black" />
				<path d="M29.697 93.3333H25.4545V97.5757H29.697V93.3333Z" fill="black" />
				<path d="M38.1818 93.3333H33.9394V97.5757H38.1818V93.3333Z" fill="black" />
				<path d="M42.4242 93.3333H38.1818V97.5757H42.4242V93.3333Z" fill="black" />
				<path d="M46.6667 93.3333H42.4243V97.5757H46.6667V93.3333Z" fill="black" />
				<path d="M50.9091 93.3333H46.6667V97.5757H50.9091V93.3333Z" fill="black" />
				<path d="M59.3939 93.3333H55.1515V97.5757H59.3939V93.3333Z" fill="black" />
				<path d="M63.6363 93.3333H59.3939V97.5757H63.6363V93.3333Z" fill="black" />
				<path d="M67.8788 93.3333H63.6364V97.5757H67.8788V93.3333Z" fill="black" />
				<path d="M72.1212 93.3333H67.8788V97.5757H72.1212V93.3333Z" fill="black" />
				<path d="M89.0909 93.3333H84.8485V97.5757H89.0909V93.3333Z" fill="black" />
				<path d="M93.3334 93.3333H89.0909V97.5757H93.3334V93.3333Z" fill="black" />
				<path d="M106.061 93.3333H101.818V97.5757H106.061V93.3333Z" fill="black" />
				<path d="M123.03 93.3333H118.788V97.5757H123.03V93.3333Z" fill="black" />
				<path d="M127.273 93.3333H123.03V97.5757H127.273V93.3333Z" fill="black" />
				<path d="M135.758 93.3333H131.515V97.5757H135.758V93.3333Z" fill="black" />
				<path d="M4.24242 97.5757H0V101.818H4.24242V97.5757Z" fill="black" />
				<path d="M21.2122 97.5757H16.9697V101.818H21.2122V97.5757Z" fill="black" />
				<path d="M38.1818 97.5757H33.9394V101.818H38.1818V97.5757Z" fill="black" />
				<path d="M42.4242 97.5757H38.1818V101.818H42.4242V97.5757Z" fill="black" />
				<path d="M46.6667 97.5757H42.4243V101.818H46.6667V97.5757Z" fill="black" />
				<path d="M59.3939 97.5757H55.1515V101.818H59.3939V97.5757Z" fill="black" />
				<path d="M67.8788 97.5757H63.6364V101.818H67.8788V97.5757Z" fill="black" />
				<path d="M93.3334 97.5757H89.0909V101.818H93.3334V97.5757Z" fill="black" />
				<path d="M110.303 97.5757H106.061V101.818H110.303V97.5757Z" fill="black" />
				<path d="M123.03 97.5757H118.788V101.818H123.03V97.5757Z" fill="black" />
				<path d="M127.273 97.5757H123.03V101.818H127.273V97.5757Z" fill="black" />
				<path d="M131.515 97.5757H127.273V101.818H131.515V97.5757Z" fill="black" />
				<path d="M140 97.5757H135.758V101.818H140V97.5757Z" fill="black" />
				<path d="M4.24242 101.818H0V106.061H4.24242V101.818Z" fill="black" />
				<path d="M12.7273 101.818H8.48486V106.061H12.7273V101.818Z" fill="black" />
				<path d="M16.9697 101.818H12.7273V106.061H16.9697V101.818Z" fill="black" />
				<path d="M25.4545 101.818H21.2121V106.061H25.4545V101.818Z" fill="black" />
				<path d="M29.697 101.818H25.4545V106.061H29.697V101.818Z" fill="black" />
				<path d="M33.9394 101.818H29.697V106.061H33.9394V101.818Z" fill="black" />
				<path d="M46.6667 101.818H42.4243V106.061H46.6667V101.818Z" fill="black" />
				<path d="M50.9091 101.818H46.6667V106.061H50.9091V101.818Z" fill="black" />
				<path d="M55.1515 101.818H50.9091V106.061H55.1515V101.818Z" fill="black" />
				<path d="M59.3939 101.818H55.1515V106.061H59.3939V101.818Z" fill="black" />
				<path d="M67.8788 101.818H63.6364V106.061H67.8788V101.818Z" fill="black" />
				<path d="M76.3636 101.818H72.1212V106.061H76.3636V101.818Z" fill="black" />
				<path d="M84.8485 101.818H80.6061V106.061H84.8485V101.818Z" fill="black" />
				<path d="M97.5757 101.818H93.3333V106.061H97.5757V101.818Z" fill="black" />
				<path d="M106.061 101.818H101.818V106.061H106.061V101.818Z" fill="black" />
				<path d="M110.303 101.818H106.061V106.061H110.303V101.818Z" fill="black" />
				<path d="M114.545 101.818H110.303V106.061H114.545V101.818Z" fill="black" />
				<path d="M118.788 101.818H114.545V106.061H118.788V101.818Z" fill="black" />
				<path d="M123.03 101.818H118.788V106.061H123.03V101.818Z" fill="black" />
				<path d="M38.1818 106.061H33.9394V110.303H38.1818V106.061Z" fill="black" />
				<path d="M55.1515 106.061H50.9091V110.303H55.1515V106.061Z" fill="black" />
				<path d="M59.3939 106.061H55.1515V110.303H59.3939V106.061Z" fill="black" />
				<path d="M63.6363 106.061H59.3939V110.303H63.6363V106.061Z" fill="black" />
				<path d="M72.1212 106.061H67.8788V110.303H72.1212V106.061Z" fill="black" />
				<path d="M84.8485 106.061H80.6061V110.303H84.8485V106.061Z" fill="black" />
				<path d="M93.3334 106.061H89.0909V110.303H93.3334V106.061Z" fill="black" />
				<path d="M101.818 106.061H97.5757V110.303H101.818V106.061Z" fill="black" />
				<path d="M106.061 106.061H101.818V110.303H106.061V106.061Z" fill="black" />
				<path d="M123.03 106.061H118.788V110.303H123.03V106.061Z" fill="black" />
				<path d="M131.515 106.061H127.273V110.303H131.515V106.061Z" fill="black" />
				<path d="M140 106.061H135.758V110.303H140V106.061Z" fill="black" />
				<path d="M42.4242 110.303H38.1818V114.545H42.4242V110.303Z" fill="black" />
				<path d="M63.6363 110.303H59.3939V114.545H63.6363V110.303Z" fill="black" />
				<path d="M67.8788 110.303H63.6364V114.545H67.8788V110.303Z" fill="black" />
				<path d="M72.1212 110.303H67.8788V114.545H72.1212V110.303Z" fill="black" />
				<path d="M80.6061 110.303H76.3636V114.545H80.6061V110.303Z" fill="black" />
				<path d="M89.0909 110.303H84.8485V114.545H89.0909V110.303Z" fill="black" />
				<path d="M93.3334 110.303H89.0909V114.545H93.3334V110.303Z" fill="black" />
				<path d="M101.818 110.303H97.5757V114.545H101.818V110.303Z" fill="black" />
				<path d="M106.061 110.303H101.818V114.545H106.061V110.303Z" fill="black" />
				<path d="M114.545 110.303H110.303V114.545H114.545V110.303Z" fill="black" />
				<path d="M123.03 110.303H118.788V114.545H123.03V110.303Z" fill="black" />
				<path d="M131.515 110.303H127.273V114.545H131.515V110.303Z" fill="black" />
				<path d="M135.758 110.303H131.515V114.545H135.758V110.303Z" fill="black" />
				<path d="M38.1818 114.545H33.9394V118.788H38.1818V114.545Z" fill="black" />
				<path d="M42.4242 114.545H38.1818V118.788H42.4242V114.545Z" fill="black" />
				<path d="M46.6667 114.545H42.4243V118.788H46.6667V114.545Z" fill="black" />
				<path d="M59.3939 114.545H55.1515V118.788H59.3939V114.545Z" fill="black" />
				<path d="M72.1212 114.545H67.8788V118.788H72.1212V114.545Z" fill="black" />
				<path d="M89.0909 114.545H84.8485V118.788H89.0909V114.545Z" fill="black" />
				<path d="M93.3334 114.545H89.0909V118.788H93.3334V114.545Z" fill="black" />
				<path d="M97.5757 114.545H93.3333V118.788H97.5757V114.545Z" fill="black" />
				<path d="M101.818 114.545H97.5757V118.788H101.818V114.545Z" fill="black" />
				<path d="M106.061 114.545H101.818V118.788H106.061V114.545Z" fill="black" />
				<path d="M123.03 114.545H118.788V118.788H123.03V114.545Z" fill="black" />
				<path d="M127.273 114.545H123.03V118.788H127.273V114.545Z" fill="black" />
				<path d="M131.515 114.545H127.273V118.788H131.515V114.545Z" fill="black" />
				<path d="M135.758 114.545H131.515V118.788H135.758V114.545Z" fill="black" />
				<path d="M38.1818 118.788H33.9394V123.03H38.1818V118.788Z" fill="black" />
				<path d="M46.6667 118.788H42.4243V123.03H46.6667V118.788Z" fill="black" />
				<path d="M59.3939 118.788H55.1515V123.03H59.3939V118.788Z" fill="black" />
				<path d="M80.6061 118.788H76.3636V123.03H80.6061V118.788Z" fill="black" />
				<path d="M89.0909 118.788H84.8485V123.03H89.0909V118.788Z" fill="black" />
				<path d="M106.061 118.788H101.818V123.03H106.061V118.788Z" fill="black" />
				<path d="M110.303 118.788H106.061V123.03H110.303V118.788Z" fill="black" />
				<path d="M114.545 118.788H110.303V123.03H114.545V118.788Z" fill="black" />
				<path d="M118.788 118.788H114.545V123.03H118.788V118.788Z" fill="black" />
				<path d="M123.03 118.788H118.788V123.03H123.03V118.788Z" fill="black" />
				<path d="M127.273 118.788H123.03V123.03H127.273V118.788Z" fill="black" />
				<path d="M135.758 118.788H131.515V123.03H135.758V118.788Z" fill="black" />
				<path d="M140 118.788H135.758V123.03H140V118.788Z" fill="black" />
				<path d="M38.1818 123.03H33.9394V127.273H38.1818V123.03Z" fill="black" />
				<path d="M42.4242 123.03H38.1818V127.273H42.4242V123.03Z" fill="black" />
				<path d="M46.6667 123.03H42.4243V127.273H46.6667V123.03Z" fill="black" />
				<path d="M55.1515 123.03H50.9091V127.273H55.1515V123.03Z" fill="black" />
				<path d="M59.3939 123.03H55.1515V127.273H59.3939V123.03Z" fill="black" />
				<path d="M67.8788 123.03H63.6364V127.273H67.8788V123.03Z" fill="black" />
				<path d="M72.1212 123.03H67.8788V127.273H72.1212V123.03Z" fill="black" />
				<path d="M84.8485 123.03H80.6061V127.273H84.8485V123.03Z" fill="black" />
				<path d="M97.5757 123.03H93.3333V127.273H97.5757V123.03Z" fill="black" />
				<path d="M106.061 123.03H101.818V127.273H106.061V123.03Z" fill="black" />
				<path d="M110.303 123.03H106.061V127.273H110.303V123.03Z" fill="black" />
				<path d="M123.03 123.03H118.788V127.273H123.03V123.03Z" fill="black" />
				<path d="M127.273 123.03H123.03V127.273H127.273V123.03Z" fill="black" />
				<path d="M135.758 123.03H131.515V127.273H135.758V123.03Z" fill="black" />
				<path d="M140 123.03H135.758V127.273H140V123.03Z" fill="black" />
				<path d="M38.1818 127.273H33.9394V131.515H38.1818V127.273Z" fill="black" />
				<path d="M50.9091 127.273H46.6667V131.515H50.9091V127.273Z" fill="black" />
				<path d="M55.1515 127.273H50.9091V131.515H55.1515V127.273Z" fill="black" />
				<path d="M76.3636 127.273H72.1212V131.515H76.3636V127.273Z" fill="black" />
				<path d="M80.6061 127.273H76.3636V131.515H80.6061V127.273Z" fill="black" />
				<path d="M93.3334 127.273H89.0909V131.515H93.3334V127.273Z" fill="black" />
				<path d="M97.5757 127.273H93.3333V131.515H97.5757V127.273Z" fill="black" />
				<path d="M106.061 127.273H101.818V131.515H106.061V127.273Z" fill="black" />
				<path d="M110.303 127.273H106.061V131.515H110.303V127.273Z" fill="black" />
				<path d="M114.545 127.273H110.303V131.515H114.545V127.273Z" fill="black" />
				<path d="M118.788 127.273H114.545V131.515H118.788V127.273Z" fill="black" />
				<path d="M127.273 127.273H123.03V131.515H127.273V127.273Z" fill="black" />
				<path d="M131.515 127.273H127.273V131.515H131.515V127.273Z" fill="black" />
				<path d="M50.9091 131.515H46.6667V135.758H50.9091V131.515Z" fill="black" />
				<path d="M55.1515 131.515H50.9091V135.758H55.1515V131.515Z" fill="black" />
				<path d="M59.3939 131.515H55.1515V135.758H59.3939V131.515Z" fill="black" />
				<path d="M72.1212 131.515H67.8788V135.758H72.1212V131.515Z" fill="black" />
				<path d="M84.8485 131.515H80.6061V135.758H84.8485V131.515Z" fill="black" />
				<path d="M89.0909 131.515H84.8485V135.758H89.0909V131.515Z" fill="black" />
				<path d="M93.3334 131.515H89.0909V135.758H93.3334V131.515Z" fill="black" />
				<path d="M101.818 131.515H97.5757V135.758H101.818V131.515Z" fill="black" />
				<path d="M110.303 131.515H106.061V135.758H110.303V131.515Z" fill="black" />
				<path d="M123.03 131.515H118.788V135.758H123.03V131.515Z" fill="black" />
				<path d="M127.273 131.515H123.03V135.758H127.273V131.515Z" fill="black" />
				<path d="M131.515 131.515H127.273V135.758H131.515V131.515Z" fill="black" />
				<path d="M38.1818 135.758H33.9394V140H38.1818V135.758Z" fill="black" />
				<path d="M63.6363 135.758H59.3939V140H63.6363V135.758Z" fill="black" />
				<path d="M76.3636 135.758H72.1212V140H76.3636V135.758Z" fill="black" />
				<path d="M80.6061 135.758H76.3636V140H80.6061V135.758Z" fill="black" />
				<path d="M97.5757 135.758H93.3333V140H97.5757V135.758Z" fill="black" />
				<path d="M106.061 135.758H101.818V140H106.061V135.758Z" fill="black" />
				<path d="M114.545 135.758H110.303V140H114.545V135.758Z" fill="black" />
				<path d="M118.788 135.758H114.545V140H118.788V135.758Z" fill="black" />
				<path d="M135.758 135.758H131.515V140H135.758V135.758Z" fill="black" />
				<path
					d="M19.5581 0.00238037H10.1388C5.54828 0.00238037 1.65917 3.03533 0.415758 7.18073C0.28717 7.60956 0.186794 8.05026 0.117006 8.50076C0.0400909 8.99789 0 9.5069 0 10.0251V19.668C0 25.1973 4.54958 29.6937 10.1388 29.6937L14.8485 29.6946L19.5581 29.6934C25.1471 29.6934 29.697 25.197 29.697 19.6677V10.0251C29.697 4.4985 25.1474 0.00238037 19.5581 0.00238037ZM25.2424 19.668C25.2424 22.7399 22.6926 25.2392 19.5569 25.2392L14.8485 25.2401L10.14 25.2389C7.00433 25.2389 4.45455 22.7396 4.45455 19.668V10.0251C4.45455 9.0656 4.70341 8.16222 5.14144 7.37287C5.56313 6.61352 6.15975 5.96048 6.87812 5.46603C7.80169 4.83022 8.92632 4.45693 10.1385 4.45693H19.5581C22.6926 4.45693 25.2424 6.95474 25.2424 10.0251V19.668Z"
					fill="black"
				/>
				<path
					d="M129.861 0.00238037H120.442C115.851 0.00238037 111.962 3.03533 110.719 7.18073C110.59 7.60956 110.49 8.05026 110.42 8.50076C110.343 8.99789 110.303 9.5069 110.303 10.0251V19.668C110.303 25.1973 114.853 29.6937 120.442 29.6937L125.152 29.6946L129.861 29.6934C135.45 29.6934 140 25.197 140 19.6677V10.0251C140 4.4985 135.45 0.00238037 129.861 0.00238037ZM135.545 19.668C135.545 22.7399 132.996 25.2392 129.86 25.2392L125.152 25.2401L120.443 25.2389C117.307 25.2389 114.758 22.7396 114.758 19.668V10.0251C114.758 9.0656 115.006 8.16222 115.444 7.37287C115.866 6.61352 116.463 5.96048 117.181 5.46603C118.105 4.83022 119.229 4.45693 120.442 4.45693H129.861C132.996 4.45693 135.545 6.95474 135.545 10.0251V19.668Z"
					fill="black"
				/>
				<path
					d="M19.5581 110.305H10.1388C5.54828 110.305 1.65917 113.338 0.415758 117.484C0.28717 117.913 0.186794 118.353 0.117006 118.804C0.0400909 119.301 0 119.81 0 120.328V129.971C0 135.5 4.54958 139.997 10.1388 139.997L14.8485 139.998L19.5581 139.996C25.1471 139.996 29.697 135.5 29.697 129.971V120.328C29.697 114.802 25.1474 110.305 19.5581 110.305ZM25.2424 129.971C25.2424 133.043 22.6926 135.542 19.5569 135.542L14.8485 135.543L10.14 135.542C7.00433 135.542 4.45455 133.043 4.45455 129.971V120.328C4.45455 119.369 4.70341 118.465 5.14144 117.676C5.56313 116.917 6.15975 116.264 6.87812 115.769C7.80169 115.133 8.92632 114.76 10.1385 114.76H19.5581C22.6926 114.76 25.2424 117.258 25.2424 120.328V129.971Z"
					fill="black"
				/>
				<path
					d="M11.9659 21.2121C10.0454 21.2121 8.48309 19.6808 8.48309 17.8002V11.895C8.48309 11.3075 8.63582 10.7546 8.90322 10.2709C9.16146 9.80532 9.52698 9.40517 9.96722 9.10328C10.5327 8.71408 11.222 8.48486 11.964 8.48486H17.7329C19.6517 8.48486 21.2139 10.0144 21.2139 11.8949V17.8001C21.2139 19.6805 19.6518 21.212 17.7329 21.212H14.8485L11.9659 21.2121Z"
					fill="black"
				/>
				<path
					d="M122.269 21.2121C120.348 21.2121 118.786 19.6808 118.786 17.8002V11.895C118.786 11.3075 118.939 10.7546 119.206 10.2709C119.464 9.80532 119.83 9.40517 120.27 9.10328C120.836 8.71408 121.525 8.48486 122.267 8.48486H128.036C129.955 8.48486 131.517 10.0144 131.517 11.8949V17.8001C131.517 19.6805 129.955 21.212 128.036 21.212H125.152L122.269 21.2121Z"
					fill="black"
				/>
				<path
					d="M11.9659 131.515C10.0454 131.515 8.48309 129.984 8.48309 128.103V122.198C8.48309 121.611 8.63582 121.058 8.90322 120.574C9.16146 120.108 9.52698 119.708 9.96722 119.406C10.5327 119.017 11.222 118.788 11.964 118.788H17.7329C19.6517 118.788 21.2139 120.317 21.2139 122.198V128.103C21.2139 129.984 19.6518 131.515 17.7329 131.515H14.8485L11.9659 131.515Z"
					fill="black"
				/>
			</svg>
		</div>
	</div>
</template>

<style scoped lang="scss">
	.qr-code {
		position: relative;

		@include mobile {
			display: none;
		}
		.hover {
			pointer-events: none;
			position: absolute;
			transform: translate(-50%, -50%) scale(0.9);
			top: 50%;
			left: 50%;
			opacity: 0;
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			padding: 20px;
			width: 180px;
			height: 180px;
			background: #ffffff;
			box-shadow: 0px 55px 80px rgba(0, 0, 0, 0.06), 0px 27.8438px 34.875px rgba(0, 0, 0, 0.0405), 0px 11px 13px rgba(0, 0, 0, 0.03), 0px 2.40625px 4.625px rgba(0, 0, 0, 0.0195);
			border-radius: 20px;
			transition: all 0.1s ease-in-out;
		}

		&:hover {
			.hover {
				opacity: 1;
				pointer-events: all;
				transform: translate(-50%, -50%) scale(1);
			}
		}
	}
</style>
